import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DisplayOrientation } from '../../models/device.model';
import { PlatformService } from '../platform/platform.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private displayOrientation = new BehaviorSubject<DisplayOrientation>('portrait');
  public displayOrientation$ = this.displayOrientation.asObservable();

  private isOrientationListenerInitialized = false;

  constructor(private platformService: PlatformService) {
    if (!this.isOrientationListenerInitialized && this.platformService.isBrowser()) {
      this.initializeOrientationListener();
      this.isOrientationListenerInitialized = true;
    }
  }

  isAndroid(): boolean {
    return /Android/i.test(navigator.userAgent);
  }

  isIos(): boolean {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  toggleDisplayOrientation(): void {
    if(this.displayOrientation.value === 'portrait'){
      this.displayOrientation.next('landscape');
    } else {
      this.displayOrientation.next('portrait');
    }
  }

  private initializeOrientationListener(): void {
    if (window.screen && window.screen.orientation) {
      window.screen.orientation.addEventListener('change', () => this.updateOrientation());
    } else {
      const mediaQuery = window.matchMedia('(orientation: portrait)');
      mediaQuery.addEventListener('change', () => this.updateOrientation());
    }
  }

  updateOrientation(): void {
    const currentOrientation = this.getCurrentOrientation();
    this.displayOrientation.next(currentOrientation);
  }

  private getCurrentOrientation(): DisplayOrientation {
    if (window.screen && window.screen.orientation) {
      return (
        window.screen.orientation.type === 'portrait-primary' ||
        window.screen.orientation.type === 'portrait-secondary'
      ) ? 'portrait' : 'landscape';
    }
    return window.matchMedia('(orientation: portrait)').matches ? 'portrait' : 'landscape';
  }
}
